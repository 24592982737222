import React, { useEffect, useState } from "react";
import { MenuItem, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/";
import GenericControllerDropdownInput from "./GenericFormFields/DropdownInput";
import GenericControllerCheckboxInput from "./GenericFormFields/CheckboxInput";
import GenericControllerTextInput from "./GenericFormFields/TextInput";
import { addBusinessDays, dispositionFollowUp, rosemarkAppDispositionFollowUp, rosemarkLeadDispositionFollowUp, } from "../utils/dateUtils";
var StatusContact = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var _m = useState(props.data.status), status = _m[0], setStatus = _m[1];
    var activeDispositions = [
        2, 4, 5, 6, 8, 9, 10, 12, 13, 14, 15, 19, 22, 24, 28, 29, 30, 32,
    ];
    var control = props.control, watch = props.watch, errors = props.errors, data = props.data, referrals = props.referrals, dispositionGroup = props.dispositionGroup, dispositionId = props.dispositionId, setDispositionId = props.setDispositionId, resetField = props.resetField, anotherCall = props.anotherCall, setAnotherCall = props.setAnotherCall;
    var leadType = ((_a = data === null || data === void 0 ? void 0 : data.lead_type) === null || _a === void 0 ? void 0 : _a.id) || "0";
    var _o = useState((_b = data === null || data === void 0 ? void 0 : data.follow_up_date) !== null && _b !== void 0 ? _b : ""), followUpDate = _o[0], setFollowUpDate = _o[1];
    var watchAnotherCall = watch("another_call");
    var lead_type = (_g = (_d = (_c = props === null || props === void 0 ? void 0 : props.data) === null || _c === void 0 ? void 0 : _c.lead_type_id) !== null && _d !== void 0 ? _d : (_f = (_e = props === null || props === void 0 ? void 0 : props.data) === null || _e === void 0 ? void 0 : _e.lead) === null || _f === void 0 ? void 0 : _f.lead_type_id) !== null && _g !== void 0 ? _g : (_h = props === null || props === void 0 ? void 0 : props.data) === null || _h === void 0 ? void 0 : _h.lead_type;
    var is_app = ((_j = props === null || props === void 0 ? void 0 : props.data) === null || _j === void 0 ? void 0 : _j.formType) == "application"; // TODO: better way to handle checking if app needed, will break if implementing for policies
    var handleFollowUp = function (e) {
        var followUp = [5 /*financial*/, 11 /*life*/].includes(lead_type)
            ? is_app
                ? rosemarkAppDispositionFollowUp(e.toString())
                : rosemarkLeadDispositionFollowUp(e.toString())
            : dispositionFollowUp(e.toString());
        if (followUp !== "") {
            setFollowUpDate(followUp);
            resetField("follow_up_date", { defaultValue: followUp });
        }
        else {
            resetField("follow_up_date", { defaultValue: "" });
        }
    };
    var handleAnotherCall = function (updateCallback) {
        var date = new Date();
        var followUp = addBusinessDays(date, 2).toISOString().slice(0, 10);
        updateCallback
            ? (function () {
                resetField("follow_up_date", { defaultValue: followUp });
                setAnotherCall(true);
            })()
            : setAnotherCall(false);
    };
    useEffect(function () {
        if (activeDispositions.includes(parseInt(dispositionId))) {
            setStatus("Active");
        }
        else {
            setStatus("Closed");
        }
    }, [dispositionId]);
    return (React.createElement(Grid, { xs: 12, container: true, spacing: 2 },
        React.createElement(Grid, { xs: 12 },
            React.createElement(Grid, { xs: 12 },
                React.createElement(Typography, { variant: "h5", component: "h3", className: "section-title" }, "Status & Contact"),
                React.createElement(Typography, null,
                    "Status: ",
                    status)),
            React.createElement(Grid, { container: true, xs: 12 },
                React.createElement(Grid, { xs: 12, sm: 6, xl: 3 },
                    React.createElement(GenericControllerDropdownInput, { name: "disposition_id", id: "disposition_id", label: "Disposition", control: control, rules: { required: true }, defaultValue: dispositionId, error: "disposition_id" in errors, handleChange: function (e) {
                            setDispositionId(e.target.value);
                            handleFollowUp(e.target.value.toString());
                        }, options: dispositionGroup
                            .sort(function (x, y) {
                            return x.id == 12 ? -1 : y.id == 12 ? 1 : 0;
                        })
                            .map(function (disposition) {
                            if (leadType != 11 &&
                                disposition.id == "19") {
                                return null;
                            }
                            var disp_name = [5, 11].includes(leadType) &&
                                parseInt(disposition.id) == 2
                                ? "Application Taken On Client"
                                : disposition.name;
                            return (React.createElement(MenuItem, { key: disposition.id, value: parseInt(disposition.id) }, disp_name));
                        }) })),
                [
                    12, 2, 4, 5, 6, 8, 9, 13, 14, 15, 20, 22, 24, 28, 29,
                    30, 32,
                ].includes(parseInt(dispositionId)) && (React.createElement(Grid, { xs: 12, sm: 6, xl: 4 },
                    React.createElement(GenericControllerTextInput, { label: "Follow-up Date", type: "date", control: control, rules: { required: true }, name: "follow_up_date", defaultValue: followUpDate, customOnChange: setFollowUpDate, error: "follow_up_date" in errors, shrinkLabel: true }))),
                [6].includes(parseInt(dispositionId)) && (React.createElement(Grid, { xs: 12, sm: 6, xl: 4 },
                    React.createElement(GenericControllerCheckboxInput, { control: control, defaultChecked: false, customOnChange: function (e) {
                            handleAnotherCall(e.target.checked);
                        }, name: "another_call", label: "Made Another Call" }))),
                React.createElement(Grid, { xs: 12, sm: 6, xl: 4 },
                    React.createElement(GenericControllerCheckboxInput, { control: control, defaultChecked: false, name: "ignore_disposition_email", label: "Disable automatic disposition email for this change." })),
                React.createElement(Grid, { xs: 12, sm: 6, xl: 4 },
                    React.createElement(GenericControllerCheckboxInput, { control: control, defaultChecked: (_k = (data === null || data === void 0 ? void 0 : data.permission_to_call) === 1) !== null && _k !== void 0 ? _k : "", name: "permission_to_call", label: "Permission to Call" })),
                React.createElement(Grid, { xs: 12, sm: 6, xl: 4 },
                    React.createElement(GenericControllerDropdownInput, { label: "Best time to reach", name: "best_time_call", id: "best_time_call", control: control, defaultValue: (_l = data === null || data === void 0 ? void 0 : data.best_time_call) !== null && _l !== void 0 ? _l : "", error: "best_time_call" in errors, options: [{ id: "AM" }, { id: "PM" }].map(function (time) {
                            return (React.createElement(MenuItem, { key: time.id, value: time.id }, time.id));
                        }) }))))));
};
export default StatusContact;
